/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.richtext-editor {
  max-width: 1024px;
}
.richtext-editor .ql-editor {
  min-height: 150px;
  font-size: 13px;
}
.richtext-editor .ql-editor .ql-size-small {
  font-size: 0.85em;
}
.richtext-editor .ql-editor .ql-size-large {
  font-size: 1.25em;
}
.richtext-editor .ql-editor .ql-size-huge {
  font-size: 1.75em;
}
.richtext-editor .ql-editor ol,
.richtext-editor .ql-editor ul {
  padding-left: 0.5em;
}
.richtext-editor .ql-editor.ql-blank::before {
  color: #bfbfbf;
}
